html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#about{padding:65px 10px 65px 10px}@media (min-width:768px){#about{padding:65px 40px 65px 40px}}@media (min-width:1024px){#about{padding:65px 80px 65px 80px}}#about .text{padding-top:25px;text-align:right;font-family:Kozuka Mincho Pro;font-size:1rem;line-height:25px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#access{background-color:rgba(0,0,0,.0509803922);padding:65px 10px 65px 10px}@media (min-width:768px){#access{padding:65px 40px 65px 40px}}@media (min-width:1024px){#access{padding:65px 80px 65px 80px}}#access div{text-align:right}@media (min-width:768px){#access .wrap{display:flex}#access .wrap>.train{width:50%;margin-right:15px}#access .wrap>.parking{width:50%;margin-left:15px}}#access .sub-headline-wrapper{margin-top:30px}#access .sub-headline{font-family:Kozuka Mincho Pro;font-size:1.4rem;font-weight:700;line-height:24px;color:#fff;background-color:#000;padding:6px 20px;text-align:right}#access .img-area{position:relative;display:inline-block;margin-bottom:80px}#access .img-area>img{border-radius:5px;width:100%;height:auto;padding-top:10px;margin-top:10px}#access .text{font-family:Kozuka Mincho Pro;font-size:.9rem;line-height:20px;text-align:right;position:absolute;bottom:-35px;left:50%;transform:translateX(-50%);width:90%;background-color:#fff;box-shadow:3px 5px 6px rgba(0,0,0,.1294117647);padding:10px 10px}#access .text.parking{bottom:-20px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#address{background-color:#000;text-align:center;padding:65px 10px 65px 10px}@media (min-width:768px){#address{padding:65px 40px 65px 40px}}@media (min-width:1024px){#address{padding:65px 80px 65px 80px}}#address .text{font-family:Kozuka Mincho Pro;font-size:1.1rem;line-height:30px;text-align:right;margin:30px 0;color:#fff}#address iframe{width:100%;height:50vh;border-radius:5px}@media (min-width:768px){#address iframe{height:60vh}}
.before-scroll{opacity:0}.scroll-animation{animation:scroll-animation 1.25s linear both}@keyframes scroll-animation{100%{opacity:1}}
@charset "UTF-8";html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#burger .bm-burger-button{position:fixed;width:27px;height:27px;top:25px;right:10px}#burger .bm-cross{background:#fff}#burger .bm-menu{background:#000}#burger .title{color:#fff;font-family:Le Monde Journal Std;font-style:italic;font-size:1.6rem;position:relative;top:40px;left:30px}#burger .menu-item-wrapper{color:#fff;font-family:Pelago;font-size:1.6rem;padding:70px 0 0 50px}#burger .menu-item{display:block;padding:7.5px 0;border-top:.5px solid gray;border-bottom:.5px solid gray;margin-right:30px}#burger .contact{position:absolute;right:10px}#burger .contact .text{text-align:left;color:#fff;font-family:Le Monde Journal Std;font-size:1.1rem;margin:50px 10px 0 0}#burger .contact .instagram{color:#fff;font-family:Pelago;font-size:1.25rem;margin-top:30px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#contact{background-color:#ffd22d;height:225px;text-align:center}#contact .title{font-family:bickham-script-pro-3,sans-serif;font-size:6rem;padding-top:30px}#contact .text{font-family:Kozuka Mincho Pro;font-size:1.1rem;line-height:25px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.equipment ul{display:flex;justify-content:flex-end;margin:5px 0 0 0}.equipment ul li{color:#fff;font-family:Kozuka Mincho Pro;font-size:1rem;padding:3px;border-radius:4px;margin-left:5px}.equipment.first{margin-top:20px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}footer{position:fixed;bottom:0;width:100%;height:70px;background:#000 0 0 no-repeat padding-box;opacity:1;display:flex;justify-content:space-between;align-items:center;text-align:center;z-index:10}footer div{width:33.3333333333%}footer .title{font-family:Pelago;font-weight:600;font-size:12px;line-height:16px;color:#fff;margin-top:5px}footer .call img{width:23px;height:23px}footer .email img{width:30px;height:23px}footer .instagram img{width:25px;height:23px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}header{position:fixed;background-color:#000;top:0;left:0;width:100%;height:65px;z-index:10}header div{position:fixed;top:25px;left:16px;right:16px;height:27px}header p{color:#fff;font-family:Le Monde Journal Std;font-style:italic;font-size:1.5rem;line-height:24px;letter-spacing:0;opacity:1}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.headline{height:64px;position:relative;margin-bottom:10px}.headline>div{display:flex}.headline .title{color:#000;font-family:bickham-script-pro-3,sans-serif;font-size:6.5rem}.headline .title>span{color:#ba8747}.headline .white{color:#fff}.headline .sub-title{position:relative;top:40px;margin-left:17.5px;font-family:Kozuka Mincho Pro;font-size:1rem}@media (min-width:1024px){.headline .sub-title{top:50px;left:30px}}.headline>img{height:10px;width:100%;position:absolute;bottom:0;left:-3px}@media (min-width:375px){.headline>img{width:92%}}@media (min-width:768px){.headline>img{width:47.5%;left:-6px}}@media (min-width:1024px){.headline>img{bottom:-15px;left:-15px}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#icons{position:relative;left:5px;position:fixed;top:50%;transform:translateY(-85%);z-index:100}@media (min-width:768px){#icons{left:10px}}@media (min-width:1024px){#icons{left:20px}}#icons .call{position:relative;top:0;width:35px;height:35px;background-color:rgba(0,0,0,.8);border-radius:50%}#icons .call img{position:absolute;top:7.5px;left:7.5px;width:20px;height:20px}#icons .email{position:relative;top:40px;width:35px;height:35px;background-color:rgba(0,0,0,.8);border-radius:50%}#icons .email img{position:absolute;top:10.2px;left:7.3px;width:20px;height:15px}#icons .instagram{position:relative;top:80px;width:35px;height:35px;background-color:rgba(0,0,0,.8);border-radius:50%}#icons .instagram img{position:absolute;top:7.5px;left:7.5px;width:20px;height:20px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.locker-room .line{border:.5px solid #ba8747;position:absolute;right:5px;width:50%}@media (min-width:768px){.locker-room .line{width:30%;right:25px}}@media (min-width:1024px){.locker-room .line{right:50px;width:25%}}.locker-room .line-area{border-left:1px solid #ba8747;border-bottom:1px solid #ba8747;height:100px;width:95%;position:relative;left:-7px;bottom:80px}@media (min-width:768px){.locker-room .line-area{left:-15px}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.meeting-room{margin-top:20px;margin-bottom:20px}@media (min-width:768px){.meeting-room{position:relative}}.meeting-room .line{border:.5px solid #ba8747;position:absolute;right:5px;width:60%}@media (min-width:768px){.meeting-room .line{right:-15px;width:70%}}@media (min-width:1024px){.meeting-room .line{right:-30px}}.meeting-room .line-area{border-left:1px solid #ba8747;border-bottom:1px solid #ba8747;height:100px;width:95%;position:relative;left:-7px;bottom:80px}@media (min-width:768px){.meeting-room .line-area{left:-15px}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#news{padding:65px 10px 65px 10px}@media (min-width:768px){#news{padding:65px 40px 65px 40px}}@media (min-width:1024px){#news{padding:65px 80px 65px 80px}}#news>.instagram-embeded{margin-top:30px;display:flex;justify-content:center;width:100%;overflow:scroll;height:60vh}@media (min-width:768px){#news>.instagram-embeded>div{width:1000px}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.price-table{border-collapse:collapse;margin-top:7px;display:flex;justify-content:flex-end}.price-table td{border:solid 1px #fff;padding:3px 6px;font-family:Kozuka Mincho Pro;color:#fff;font-size:1rem}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#rooms{padding:65px 10px 65px 10px;background-color:#000}@media (min-width:768px){#rooms{padding:65px 40px 65px 40px}#rooms .wrap{display:flex;justify-content:space-between}#rooms .wrap .other-wrap{width:45%}}@media (min-width:1024px){#rooms{padding:65px 80px 65px 80px}#rooms .wrap .other-wrap{width:47%}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#schedule{padding:65px 10px 65px 10px;text-align:center}@media (min-width:768px){#schedule{padding:65px 40px 65px 40px}}@media (min-width:1024px){#schedule{padding:65px 80px 65px 80px}}#schedule .schedule{margin-top:30px;width:100%;height:auto}@media (min-width:768px){#schedule .schedule{margin-top:50px;width:80%}}@media (min-width:1024px){#schedule .schedule{margin-top:50px;width:75%}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}#service{background-color:rgba(0,0,0,.1490196078);padding:65px 10px 65px 10px;font-family:Kozuka Mincho Pro}@media (min-width:768px){#service{padding:65px 40px 65px 40px}#service .block-wrapper{width:350px;margin:0 0 0 auto}}@media (min-width:1024px){#service{padding:65px 80px 65px 80px}#service .block-wrapper{width:450px}}#service .block{display:flex;margin-bottom:20px}#service .block.first{margin-top:50px}#service .block img{width:110px;height:100px;position:relative;left:-40px}@media (min-width:375px){#service .block img{left:-30px}}@media (min-width:768px){#service .block img{left:-20px}}#service .block div{position:relative;left:-20px}@media (min-width:375px){#service .block div{left:0}}#service .block div.first{top:15px}#service .block div.second{top:5px}#service .block div.third{top:18px}#service .block .topic{font-size:1.3rem;margin-bottom:8px}#service .block .sentence{font-size:.9rem;padding-left:15px;width:100%}@media (min-width:375px){#service .block .sentence{line-height:18px}}@media (min-width:768px){#service .block .sentence{width:230px}}@media (min-width:1024px){#service .block .sentence{width:300px}}#service .term-btn{position:absolute;right:40px;border-bottom:1px #000 solid;font-size:1.2rem}#service .term-btn:hover{opacity:.5}#service .term-btn img{width:20px;height:20px}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.swiper{margin-top:10px}.swiper img{width:100%;height:auto;border-radius:5px}.swiper .swiper-pagination-bullet{background-color:#000}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}@media (min-width:768px){.studio{width:75%;margin:0 0 0 auto}}@media (min-width:1024px){.studio{width:60%}}.studio .line{border:.5px solid #ba8747;position:absolute;right:5px;width:30%}@media (min-width:768px){.studio .line{right:25px;width:20%}}@media (min-width:1024px){.studio .line{right:50px;width:15%}}.studio .img-list{margin-top:10px}.studio .img-list img{width:100%;height:auto}.studio .img-list .first{margin-bottom:2px}.studio .img-list div{display:flex;justify-content:space-between}.studio .img-list .left,.studio .img-list .right{width:50%;height:50%}.studio .img-list .left{padding-right:1px}.studio .img-list .right{padding-left:1px}.studio .line-area{border-left:1px solid #ba8747;border-bottom:1px solid #ba8747;height:100px;width:95%;position:relative;left:-7px;bottom:80px}@media (min-width:768px){.studio .line-area{left:-15px}}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.sub-headline p{text-align:right;margin:30px 0 3px 0;font-family:Pelago;font-weight:600;font-size:2rem;line-height:33px;color:#fff}
html{font-size:12px}@media (min-width:375px){html{font-size:13px}}@media (min-width:1024px){html{font-size:15px}}.animation-wrapper{width:100%;height:100%;position:absolute;top:0;left:0;z-index:9999;background-color:#000;opacity:1;transition:opacity .25s ease-out}.animation-wrapper.fade-out{opacity:0}.animation-wrapper .animation-video{max-width:100%;max-height:100%;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}#top{width:100%;min-height:100vh;background-color:#000;display:grid;place-items:center;opacity:0;transition:opacity .25s ease-in}#top.fade-in{opacity:1}#top .swiper{width:100vw;position:relative}#top .swiper-wrapper{transition-timing-function:linear}#top img{width:100%;height:auto;max-height:100vh;border-radius:0}#top .scroll-area{overflow:hidden;width:25%;height:auto;position:absolute;bottom:20px;right:0;z-index:10}#top .scroll-area .scroll{width:142px;height:142px}